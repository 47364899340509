/**
 * Add a non-breaking space to text nodes to prevent widows.
 * Karl von Randow, 2010
 */

$.fn.clwidont = function() {

	function s(n) {
		var contents = $(n).contents();
		for ( var i = contents.length - 1; i >= 0; i--) {
			var nodeType = contents[i].nodeType;
			if (nodeType == 3) {
				var nodeValue = contents[i].nodeValue;
				var j = nodeValue.search(/\s\S*$/);
				if (j != -1) {
					contents[i].nodeValue = nodeValue.substring(0, j) + "\xa0" + nodeValue.substring(j + 1);
					return true;
				}
			} else if (nodeType == 1) {
				if (s(contents[i])) {
					return true;
				}
			}
		}
	}

	$(this).each(function() {
		s(this);
	});
};
