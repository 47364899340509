$(function(){
	
	var SITE = (function(){
		
		// Content variables
		var els = {
			$body: $("body"),
			$container: $(".addthis-container"),
			$trigger: $(".addthis-trigger")
		};
		
		function init() {
			layout();
		}
		
		function layout() {
			// Do content layout
			els.$body.find('.films').padme(6);
			
			els.$body.find('img.lazy').lazyload({ effect: 'fadeIn' });

			els.$body.find('*[data-modal]').each(function(){
				var $this = $(this);
				
				$this.attr('href', $this.data('modal')).magnificPopup({
					type: 'ajax',
					// focus: '#form-username',
					overflowY: 'scroll',
					removalDelay: 300,
					mainClass: 'my-mfp-slide-bottom'
				});
			});
			
			els.$body.find('.x-description p').condense({
				moreText: 'More',
				lessText: 'Hide',
				condensedLength: 500,  
				minTrail: 100,
				ellipsis: "…"
			});

			els.$trigger.on("click", function(ev) {
				ev.preventDefault();
				els.$container.toggleClass("visible");
			});

			var $widowables = els.$body.find('.films p a, .film-description p');
			if ( $widowables.length ) { $widowables.clwidont(); }
		}

		// Return an object that exposes our greeting function publicly
		return {
			init: init
		};

	})();
	
	SITE.init();
	
});